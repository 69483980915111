main .hero-section {
  min-height: 480px;
  padding-top: 40px;
  padding-bottom: 120px;
}

.web-footer {
  min-height: 200px;
  padding-top: 50px;
  padding-bottom: 50px;
}